import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
} from "@mui/material";
import {useEffect, useState} from "react";
import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import * as XLSX from 'xlsx';
import sendRequest from "../services/sendRequest";
import MoonLoader from "react-spinners/MoonLoader";

const UnknownDealUpload =({open, close, selected, setRefresh}) => {

const { enqueueSnackbar } = useSnackbar()
const [items, setItems] = useState([]);
const [formData, setFormData] = useState({});
const [fullWidth, setFullWidth] = React.useState(true);
const [maxWidth, setMaxWidth] = React.useState('sm');
let [loading, setLoading] = useState(false);
let [color, setColor] = useState("#000000");
const page ="deals";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
const handleClose = () => {
    close();
  };

  useEffect(() => {
  });

  // handle file upload
  const handleFileUpload = e => {
    setLoading(true)
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const fileData = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const fileDataList = fileData.split('\n');
      const columns = fileDataList[0].split(',');
      const numberOfColumns = columns.length;
      if (numberOfColumns == 2) {
        try {
          const items = [];
      
          for (let i = 0; i < fileDataList.length; i++) {
            const singleObject = fileDataList[i].split(',');
            
            if (singleObject.length !== 2) {
              throw new Error(`Invalid data format at index ${i}`);
            }
            
            const newObject = {
              title: singleObject[0],
              custom_id: singleObject[1],
            };
      
            items.push(newObject);
          }
      
          const response = await sendRequest({
            requestType: "post",
            endpoint: `${page}/custom_bulk_create/`,
            requestData: items,
          });
      
          if (response.status === 200) {
            setItems([]);
            handleSuccess();
            handleClose();
          } else {
            handleError();
          }
      
        } catch (error) {
          console.error("An error occurred:", error);
          handleError();
        } finally {
          setItems([]);
          setLoading(false);
          handleClose();
        }
      } else {
        setItems([]);
        handleErrorForMoreThanTwoCoulumnsInFile();
        handleClose();
      }
    };
    reader.readAsBinaryString(file);
  }

  const handleError = () => {
    enqueueSnackbar('There is an Error with the file, Please Check the .csv file and Reupload it!', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 5000,
    });
  };

  const handleErrorForMoreThanTwoCoulumnsInFile = () => {
    enqueueSnackbar('The file should contains only 2 Columns [Title and Custom ID] with no headers', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 10000,
    });
  };

  const handleSuccess = () => {
    enqueueSnackbar('The Data updated Successfully!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 3000,
    });
  };
  return (
    <Dialog
    open={open}
    onClose={() => {
      close();
    }}
    >
      <DialogContent>
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
          >
            <MoonLoader
              color={color}
              loading={loading}
              cssOverride={override}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <DialogTitle sx={{ textAlign: 'center' }}>Upload CSV</DialogTitle>
            <DialogContent sx={{ marginBottom: 2 }}>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
              Upload a CSV file to bulk update Title and Custom ID values:
              </DialogContentText>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
                The .CSV file should contains only 2 Columns 1)Title 2)Custom ID with no headers
              </DialogContentText>
              <Box
                noValidate
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                  width: 'fit-content',
                }}
              >        
              <FormControl sx={{ mt: 2, minWidth: 120 }}>              
                <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload}/>
              </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default UnknownDealUpload;
