import "./App.css";
import {CssBaseline, ThemeProvider, Box} from "@mui/material";
import {useState} from "react";
import {LicenseInfo} from "@mui/x-license-pro";

import AppRoutes from "./routes/routes";
import {lightTheme} from "./theme/theme";
import {appStructure} from "./settings";
import {appStructureNew} from "./settings";
import useScript from "./hook/useScript";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
const is_access=localStorage.getItem("is_access")
const App = () => {
  const [open, setOpen] = useState(false);

  // Import google authentication library
  //useScript('https://accounts.google.com/gsi/client')
if(is_access=="true")
  return (
    <ThemeProvider theme={lightTheme}>
      <Box sx={{display: "flex"}}>
        <CssBaseline />
        <AppRoutes
          menuNavigationItems={appStructure}
          open={open}
          setOpen={setOpen}
        />
      </Box>
    </ThemeProvider>
  );
else{
    return (
      <ThemeProvider theme={lightTheme}>
        <Box sx={{display: "flex"}}>
          <CssBaseline />
          <AppRoutes
            menuNavigationItems={appStructureNew}
            open={open}
            setOpen={setOpen}
          />
        </Box>
      </ThemeProvider>
    );
  }
};

export default App;
