import { useEffect, useState, } from "react";
import { useSnackbar } from "notistack";
import ReactFileReader from 'react-file-reader';
import {
  DataGridPro,
  GridLinkOperator,
  GridToolbar,
  GridFilterItem,
} from "@mui/x-data-grid-pro";
import { Box, Button } from "@mui/material";
import sendRequest from "../services/sendRequest";
import { v4 as uuidv4 } from "uuid";
import CustomToolbar from "../components/customToolbar";
import { DataGridPremium } from "@mui/x-data-grid-premium";

import * as React from 'react';
import UnknowAssetsUpload from "../components/unknowAssetsUpload";
import AssetsExport from "../components/assetsExport";
import UnknownAssetsExport from "../components/unknownAssetsExport";
const ManagePageBQ = ({ page, columnsToShow, defaultParams, CustomFilter }) => {
  //console.log(CustomFilter);
  const [data, setData] = useState();
  const [assetsData, setAssetsData] = useState();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(2500);
  const [pageCount, setPageCount] = useState(0);

  const [quickFilter, setQuickFilter] = useState(false);
  const [targetValue, setTargetValue] = useState("");

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [openUnknowAssetsUpload, setOpenUnknowAssetsUpload] = useState(false);
  const [entries, setEntries] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAssetsExport, setOpenAssetsExport] = useState(false);
  const [openUnknownAssetsExport, setOpenUnknownAssetsExport] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(false);
  let [assetsDataLoadFully, setAssetsDataLoadFully] = useState(false);
  const [filters, setFilters] = useState({
    items: [
    ],
  });
  const [assetsFilters, setAssetsFilters] = useState({items: []});

  const [serverFilters, setServerFilters] = useState({
    // source: "YouTube",
    cms: "Main",
  });

  const cmsMapping = {
    main: "Main",
    deutschland: "Deutschland",
    family: "Family",
    sports: "Sport",
    misc: "Misc",
    affiliate: "Affiliate",
    sonypictures: "Sony Pictures",
    intlparacomm: "Intl Para Comm",
    usaffiliate: "US Affiliate",
    sony_kids: "Sony Kids",
  };

  const sourceMapping = {
    fb: "Facebook",
    yt: "YouTube",
  };

  const sourceMappingUnknownAssets = {
    Facebook: "Facebook",
    Youtube: "YouTube",
  };

  const sourceOptions = {
    Facebook: "Facebook",
    Facebook_Rights: "Facebook Rights",
    Youtube: "Youtube",
  };
  const yearOptions = {
    2000: "2000",
    2001: "2001",
    // 3: "2002",
    // 4: "2003",
    // 5: "2004",
    // 6: "2005",
    // 7: 2006,
    // 8: 2007,
    // 9: 2008,
    // 10: 2009,
    // 11: 2010,
    // 13: 2011,
    // 14: 2012,
    // 15: 2013,
    // 16: 2014,
    // 17: 2015,
    // 18: 2016,
    // 19: 2017,
    // 20: 2018,
    // 21: 2019,
    // 22: 2020,
    // 23: 2021,
    // 24: 2022,
    // 25: 2023,
    // 26: 2024,
  };
  const quarterOptions = {
    Q1: "Q1",
    Q2: "Q2",
    Q3: "Q3",
    Q4: "Q4",
  };

  const UnAssetFilterDetails = {
    "asset_id": [],
    "programme": [],
    "revenue": [],
    "quarter": [],
    "year": [],
    "source": [
      {
        "label": "YouTube",
        "value": "yt"
      },
      {
        "label": "Facebook",
        "value": "fb"
      }
    ],
    "cms": [
      {
        "label": "Main",
        "value": "main"
      },
      {
        "label": "Deutschland",
        "value": "deutschland"
      },
      {
        "label": "Family",
        "value": "family"
      },
      {
        "label": "Sport",
        "value": "sports"
      },
      {
        "label": "Misc",
        "value": "misc"
      },
      {
        "label": "Affiliate",
        "value": "affiliate"
      },
      {
        "label": "Sony Pictures",
        "value": "sonypictures"
      },
      {
        "label": "Intl Para Comm",
        "value": "intlparacomm"
      },
      {
        "label": "US Affiliate",
        "value": "usaffiliate"
      },
      {
        "label": "Sony Kids",
        "value": "sony_kids"
      }
    ]
  }
if(page === "unknown_assets"){
  CustomFilter = UnAssetFilterDetails;
}
console.log(CustomFilter);
  useEffect(() => {
    (async () => {
      const response = await sendRequest({
        requestType: "options",
        endpoint: `${page}`,
      });
      console.log(response);
      if (response.code) {
        console.log(response);
      } else {
        // console.log(response?.data?.actions?.POST)
        const cols = createColumns(response?.data?.actions?.POST);
        console.log(cols);
        setColumns(cols);
        const cvm = createColumnVisibilityModel(response?.data?.actions?.POST);
        // setOptions(response?.data?.actions?.POST)
        setColumnVisibilityModel(cvm);
      }
    })();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     setLoading(true);
  //     const {source, cms} = serverFilters;
  //     const s = Object.keys(sourceMapping).find(
  //       (e) => sourceMapping[e] === source
  //     );
  //     const c = Object.keys(cmsMapping).find((e) => cmsMapping[e] === cms);
  //     if (s === undefined || c === undefined) {
  //       enqueueSnackbar(`Invalid Source (${s})or CMS (${c})`, {
  //         variant: "error",
  //       });
  //       setLoading(false);
  //       return;
  //     }

  //     enqueueSnackbar("Fetching data", {variant: "info"});
  //     const endpoint =
  //       page === "assets"
  //         ? `${page}?source=${s}&cms=${c}&offset=${0 * 2500}`
  //         : `${page}`;
  //     // page === ${page}
  //     const response = await sendRequest({
  //       requestType: "get",
  //       endpoint: endpoint,

  //       //endpoint: `${page}?source=${s}&cms=${c}`,
  //       //endpoint: `${page}`,
  //     });

  //     if (response.code) {
  //       console.log(response);

  //       enqueueSnackbar(`Fetch Failed: ${response.code}`, {variant: "error"});
  //     } else {
  //       setData(response.data.results);
  //       console.log(response.data.params, response.data.count);
  //       enqueueSnackbar(`Found ${response.data.count} entries`, {
  //         variant: "success",
  //       });
  //     }
  //     // Update the page count based on the total number of records and page size

  //     console.log(response);
  //     setLoading(false);

  //     if (page === "assets") {
  //       let newData = data ? data : [];
  //       let pageNumber = 1;
  //       let shouldFetchData = true;
  //       let previousFilters = {source: s, cms: c};
  //       let getresponse;
  //       console.log(newData);
  //       do {
  //         getresponse = await sendRequest({
  //           requestType: "get",
  //           endpoint: `${page}?source=${s}&cms=${c}&offset=${
  //             pageNumber * 2500
  //           }`,
  //         });
  //         if (getresponse.data.count > 0) {
  //           newData = [...newData, ...getresponse.data.results];
  //           setData(newData);
  //           pageNumber++;
  //         }

  //         const currentFilters = {source: s, cms: c};
  //         const filtersChanged =
  //           JSON.stringify(currentFilters) !== JSON.stringify(previousFilters);

  //         // Update previous filters
  //         previousFilters = currentFilters;

  //         if (getresponse.data.count === 0 || filtersChanged) {
  //           shouldFetchData = false;
  //           setData(newData);
  //         }
  //       } while (shouldFetchData); // Continue fetching until response count becomes 0
  //       enqueueSnackbar(`Data Load completed`, {
  //         variant: "success",
  //       });
  //       console.log(data);
  //     }
  //   })();
  // }, [serverFilters]);

  useEffect(() => {
    let cancelPreviousLoop = false;
    if (quickFilter) {
      return;
    }

    (async () => {
      // setCancelLoop(false);
      setLoading(true);
      const {source, cms} = serverFilters;
      const s = Object.keys(sourceMapping).find(
        (e) => sourceMapping[e] === source
      );
      const c = Object.keys(cmsMapping).find((e) => cmsMapping[e] === cms);

      if (s === undefined && c === undefined) {
        enqueueSnackbar(`Invalid Source (${s}) or CMS (${c})`, {
          variant: "error",
        });
        setLoading(false);
        return;
      }

      if(page !== "assets") {
        enqueueSnackbar("Fetching data", {variant: "info"});
      const check ="No";
      const endpoint =
        page === "assets"
          ? `${page}?source=${s}&cms=${c}&offset=${0 * 2500}&limit=2500&filters=${check}`
          : `${page}`;

      try {
        const response = await sendRequest({  
          requestType: "get",
          endpoint: endpoint,
        });

        if (response.code) {
        console.log(response);
          enqueueSnackbar(`Fetch Failed: ${response.code}`, {variant: "error"});
        } else {
          if(page === "unknown_assets"){
            const updatedData = response.data.results.map(item => ({
              ...item, // Spread existing properties
              uid: uuidv4() // Add uid property with a unique ID
            }));
            setData(updatedData); // Update state with the new array
          } else {
            setData(response.data.results);
          }
          enqueueSnackbar(`Found ${response.data.count} entries`, {
            variant: "success",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar("Error fetching data", {variant: "error"});
      } finally {
        setLoading(false);
      }
      } else {
      enqueueSnackbar("Fetching data", {variant: "info"});
      const check ="No";
      

      try {
        if (page === "assets") {
            setLoading(true);
            let newData = [];
            let pageNumber = 0;
            let getresponse;

            do {
              if (quickFilter) {
                break;
              }
              if (cancelPreviousLoop) return; // Cancel previous loop if filters changed
              let endpoint = "";
              if(pageNumber === 0) {
                endpoint = `${page}?offset=${0}&limit=2500&filters=${check}`
                
              } else if(pageNumber === 1){
                endpoint = `${page}?offset=${2500}&limit=47500&filters=${check}`;
              } else {
                endpoint = `${page}?offset=${(pageNumber-1) * 50000}&limit=50000&filters=${check}`;
              }
              getresponse = await sendRequest({
                requestType: "get",
                endpoint: endpoint,
              });
              if (getresponse.data.count > 0) {
                newData = [...newData, ...getresponse.data.results];
                if(pageNumber === 0) {
                  setData(newData);
                  setLoading(false);
                }                
                setAssetsData(newData);
                pageNumber++;
              }
            } while (getresponse.data.count > 0); // Continue fetching until response count becomes 0
            setAssetsDataLoadFully(true);
            enqueueSnackbar(`Data Load completed`, {variant: "success"});
            console.log(newData);
            setData(newData);
          }
        } catch (error) {
        console.error("Error fetching data:", error);
        enqueueSnackbar("Error fetching data", {variant: "error"});
      } finally {
        setLoading(false);
      }
      }
    })();

    // Cleanup function to cancel previous loop when server filters change
    return () => {
      cancelPreviousLoop = true;
      //setCancelLoop(true);
    };
  }, [serverFilters, quickFilter]);

  const createColumnVisibilityModel = (ops) => {
    const model = {};
    Object.keys(ops).forEach((columnName) => {
      if (columnsToShow && !columnsToShow.includes(columnName))
        model[columnName] = false;
    });
    return model;
  };

  const createColumns = (ops) => {
    console.log(ops);
    if (page === "assets")
      return Object.entries(ops).map(([k, v]) => ({
        id: k,
        field: k,
        headerName: v.label,
        flex: 1,
        filterable: k in CustomFilter,
        ...(k === "source" && {
          type: "singleSelect",
          valueOptions: Object.values(sourceMapping),
          valueGetter: (params) => sourceMapping[params.value],
        }),
        ...(k === "cms" && {
          type: "singleSelect",
          valueOptions: Object.values(cmsMapping),
          valueGetter: (params) => cmsMapping[params.value],
        }),
      }));
    else if (page === "unknown_assets")
      return Object.entries(ops).map(([k, v]) => ({
        id: k,
        field: k,
        headerName: v.label,
        flex: 1,
        filterable: k in CustomFilter,
        ...(k === "source" && {
          type: "singleSelect",
          valueOptions: Object.values(sourceMappingUnknownAssets),
          valueGetter: (params) => sourceMappingUnknownAssets[params.value],
        }),
        ...(k === "cms" && {
          type: "singleSelect",
          valueOptions: Object.values(cmsMapping),
          valueGetter: (params) => cmsMapping[params.value],
        }),
      }));
    else if (page === "quarterly_report")
      return Object.entries(ops).map(([k, v]) => ({
        id: k,
        field: k,
        headerName: v.label,
        flex: 1,
        filterable: true,
        ...(k === "Source" && {
          type: "singleSelect",
          valueOptions: Object.values(sourceOptions),
          //valueGetter: (params) => sourceMapping[params.value],
        }),
        ...(k === "quarter" && {
          type: "singleSelect",
          valueOptions: Object.values(quarterOptions),
          //valueGetter: (params) => sourceMapping[params.value],
        }),
        // ...(k === "year" && {
        //   type: "number",
        //   // valueOptions: Object.values(yearOptions),
        //   //valueGetter: (params) => sourceMapping[params.value],
        // }),
        ...(k === "gross_revenue_client_currency" && {
          type: "number",
          //valueOptions: Object.values(yearOptions),
          //valueGetter: (params) => sourceMapping[params.value],
        }),
        ...(k === "gross_revenue_usd" && {
          type: "number",
          // valueOptions: Object.values(yearOptions),
          //valueGetter: (params) => sourceMapping[params.value],
        }),
        //type: getType(k),
        // valueOptions: getValueOptions(k),
      }));
    else
      return Object.entries(ops).map(([k, v]) => ({
        id: k,
        field: k,
        headerName: v.label,
        flex: 1,
        filterable: true,
        // ...(k === "source" && {
        //   type: "singleSelect",
        //   valueOptions: Object.values(sourceOptions),
        //   //valueGetter: (params) => sourceMapping[params.value],
        // }),
        // type: getType(k),
        //valueOptions: getValueOptions(k),
      }));
  };
  // const getType = (k) => {
  //   if (k === "Source" || k === "year" || k === "quarter") {
  //     return "singleSelect";
  //   } else if (k === "name") {
  //     return "string";
  //   } else if (
  //     k === "gross_revenue_client_currency" ||
  //     k === "gross_revenue_usd" ||
  //     k === "id"
  //   ) {
  //     return "number";
  //   } else {
  //     return "unknown"; // or handle other cases as needed
  //   }
  // };

  // function getValueOptions(field) {
  //   switch (field) {
  //     case "Source":
  //       console.log(Object.values(sourceOptions));
  //       //return [{value: "", label: "All"}, ...Object.values(sourceOptions)];
  //       return Object.values(sourceOptions);
  //     case "year":
  //       return Object.values(yearOptions);
  //     case "quarter":
  //       return Object.values(quarterOptions);
  //     default:
  //       return [];
  //   }
  // }

  console.log(data);
  const handleFilterChange = (filterModel) => {
    if(page !=='assets'){
      setAssetsDataLoadFully(true);
    }
    if(page ==='assets') {
      if(filterModel.items.length == 0){
        setData(assetsData);
      }
      setAssetsFilters(filterModel);
    } 
    if(page ==='assets' && filterModel.items.length != 0) {
      if(filterModel.items.length > 0){
        for (let i = 0; i < filterModel.items.length; i++) {
          if(filterModel.items[i].operatorValue === "isAnyOf"){
            if(filterModel.items[i]['value'][0].includes(',')){
              filterModel.items[i]['value'] = filterModel.items[i]['value'][0].split(',')
            }
          }
        }
      }      
    }
    if(page !== 'unknown_assets') {
      setFilters(filterModel);
      
    // if either cms or source exist in one or the other, or the value of either has changed, setServerFilters
    const source = filterModel.items.find(
      (i) => i.columnField === "source"
    )?.value;
    const cms = filterModel.items.find((i) => i.columnField === "cms")?.value;
    if (source === undefined || cms === undefined) return;
    if (serverFilters.source !== source || serverFilters.cms !== cms) {
      setServerFilters({
        source: source,
        cms: cms,
      });
    }
    }
  };

  if (!columns) {
    return null;
  }

  const uniqId = (row) => row.uid;
  //const uniqId = (row) => uuidv4();
  //const uniqId = (row) => `${row.id}` + Math.random();
  //const uniqId = (row) => `${row.year}${row.quarter}${row.name}`;
  //const uniqId = uuidv4();
  const defaultId = (row) => {
    if (defaultParams && "row_id" in defaultParams)
      return defaultParams["row_id"];
    return row.id
      ? row.id
      : row.custom_id_stem
        ? row.custom_id_stem
        : row.custom_id ? row.assetsId : row.assetsId;
  };

  const handleQuickFilter = async (e, filterModel) => {
    setQuickFilter(true);

    setTargetValue(e.target.value);
    console.log(filterModel);
    const {source, cms} = serverFilters;
    const s = Object.keys(sourceMapping).find(
      (e) => sourceMapping[e] === source
    );
    const c = Object.keys(cmsMapping).find((e) => cmsMapping[e] === cms);

    const response = await sendRequest({
      requestType: "post",
      endpoint: `${page}?id=${e.target.value} &cms=${c}&source=${s}`,
    });

  console.log(response);
    setData(response.data.data);
    if (e.target.value === "") {
      setQuickFilter(false);
    }
  };

  const selectedRowsForAssetAndUnknowAsset = (model) => {
    if(page === 'assets') {
      const seledtedRows = data.filter((x) => model.some((y) => y === x.id));
      setSelectedRows(seledtedRows)
    } else if(page === 'unknown_assets') {
      const seledtedRows = data.filter((x) => model.some((y) => y === x.uid));
      setSelectedRows(seledtedRows)
    }
  };

  const handleClick = async () => {    
    if(!assetsDataLoadFully) {
      setLoading(true);
      const {source, cms} = serverFilters;
      const s = Object.keys(sourceMapping).find(
        (e) => sourceMapping[e] === source
      );
      const c = Object.keys(cmsMapping).find((e) => cmsMapping[e] === cms);
    let query = "";
    const filterDetails = assetsFilters.items;
    if(filterDetails.length == 0){
      enqueueSnackbar(`Please Apply Atleast One Filter`, {
        variant: "error",
      });
      setLoading(false);
    } else {
        for (let i = 0; i < filterDetails.length; i++) {
          //This code is responsible for the CMS Custome Filter.  
          if(filterDetails[i].columnField === "cms") {
            if(i === 0) {
              query = "where "
            } else {
              query = query + " and "
            }
            query = query +"cms "
            if(filterDetails[i].operatorValue === "is") {
              if(filterDetails[i].value === "Main"){
                query = query +"= '" + "main"+"'";
              } else if(filterDetails[i].value === "Deutschland"){
                query = query +"= '" + "deutschland"+"'";
              } else if(filterDetails[i].value === "Family"){
                query = query +"= '" + "family"+"'";
              } else if(filterDetails[i].value === "Sport"){
                query = query +"= '" + "sports"+"'";
              } else if(filterDetails[i].value === "Misc"){
                query = query +"= '" + "misc"+"'";
              } else if(filterDetails[i].value === "Affiliate"){
                query = query +"= '" + "affiliate"+"'";
              } else if(filterDetails[i].value === "Sony Pictures"){
                query = query +"= '" + "sonypictures"+"'";
              } else if(filterDetails[i].value === "Intl Para Comm"){
                query = query +"= '" + "intlparacomm"+"'";
              } else if(filterDetails[i].value === "US Affiliate"){
                query = query +"= '" + "usaffiliate"+"'";
              } else if(filterDetails[i].value === "Sony Kids"){
                query = query +"= '" + "sony_kids"+"'";
              }
            }
            if(filterDetails[i].operatorValue === "is not") {
              if(filterDetails[i].value === "Main"){
                query = query +"!= '" + "main"+"'";
              } else if(filterDetails[i].value === "Deutschland"){
                query = query +"!= '" + "deutschland"+"'";
              } else if(filterDetails[i].value === "Family"){
                query = query +"!= '" + "family"+"'";
              } else if(filterDetails[i].value === "Sport"){
                query = query +"!= '" + "sports"+"'";
              } else if(filterDetails[i].value === "Misc"){
                query = query +"!= '" + "misc"+"'";
              } else if(filterDetails[i].value === "Affiliate"){
                query = query +"!= '" + "affiliate"+"'";
              } else if(filterDetails[i].value === "Sony Pictures"){
                query = query +"!= '" + "sonypictures"+"'";
              } else if(filterDetails[i].value === "Intl Para Comm"){
                query = query +"!= '" + "intlparacomm"+"'";
              } else if(filterDetails[i].value === "US Affiliate"){
                query = query +"!= '" + "usaffiliate"+"'";
              } else if(filterDetails[i].value === "Sony Kids"){
                query = query +"!= '" + "sony_kids"+"'";
              }
            }
            if(filterDetails[i].operatorValue === "isAnyOf") {
              filterDetails[i].value = filterDetails[i].value.map(item => ("Main".includes(item) ? "main" : item));
              filterDetails[i].value = filterDetails[i].value.map(item => ("Deutschland".includes(item) ? "deutschland" : item));
              filterDetails[i].value = filterDetails[i].value.map(item => ("Family".includes(item) ? "family" : item));
              filterDetails[i].value = filterDetails[i].value.map(item => ("Sport".includes(item) ? "sports" : item));
              filterDetails[i].value = filterDetails[i].value.map(item => ("Misc".includes(item) ? "misc" : item));
              filterDetails[i].value = filterDetails[i].value.map(item => ("Affiliate".includes(item) ? "affiliate" : item));
              filterDetails[i].value = filterDetails[i].value.map(item => ("Sony Pictures".includes(item) ? "sonypictures" : item));
              filterDetails[i].value = filterDetails[i].value.map(item => ("Intl Para Comm".includes(item) ? "intlparacomm" : item));
              filterDetails[i].value = filterDetails[i].value.map(item => ("US Affiliate".includes(item) ? "usaffiliate" : item));
              filterDetails[i].value = filterDetails[i].value.map(item => ("Sony Kids".includes(item) ? "sony_kids" : item));
              query = query +"IN (" + filterDetails[i].value.map(item => `'${item}'`).join(", ") +")";
            }
          }
          //This code is responsible for the Source Custome Filter. 
          if(filterDetails[i].columnField === "source") {
            if(i === 0) {
              query = "where "
            } else {
              query = query + " and "
            }
            query = query +" source "
            if(filterDetails[i].operatorValue === "is") {
              if(filterDetails[i].value === "Facebook"){
                query = query +"= '" + "fb"+"'";
              } else if(filterDetails[i].value === "YouTube"){
                query = query +"= '" + "yt"+"'";
              }
            }
            if(filterDetails[i].operatorValue === "is not") {
              if(filterDetails[i].value === "Facebook"){
                query = query +"!= '" + "fb"+"'";
              } else if(filterDetails[i].value === "YouTube"){
                query = query +"!= '" + "yt"+"'";
              }
            }
            if(filterDetails[i].operatorValue === "isAnyOf") {
              filterDetails[i].value = filterDetails[i].value.map(item => ("Facebook".includes(item) ? "fb" : item));
              filterDetails[i].value = filterDetails[i].value.map(item => ("YouTube".includes(item) ? "yt" : item));
              query = query +"IN (" + filterDetails[i].value.map(item => `'${item}'`).join(", ") +")";
            }
          }
          //This code is responsible for the Source Custome Filter. 
          if(filterDetails[i].columnField === "client_1") {
            if(i === 0) {
              query = "where "
            } else {
              query = query + " and "
            }
            query = query +" client "
            if(filterDetails[i].operatorValue === "contains") {
              query = query +"LIKE " + "'"+"%"+filterDetails[i].value+"%'";
            }
            if(filterDetails[i].operatorValue === "equals") {
              query = query +"= '" + filterDetails[i].value+"'";
            }
            if(filterDetails[i].operatorValue === "startsWith") {
              query = query +"LIKE " + "'"+filterDetails[i].value+"%'";
            }
            if(filterDetails[i].operatorValue === "endsWith") {
              query = query +"LIKE " + "'%"+filterDetails[i].value+"'";
            }
            if(filterDetails[i].operatorValue === "isEmpty") {
              query = query +"is null";
            }
            if(filterDetails[i].operatorValue === "isNotEmpty") {
              query = query +"is not null";
            }
            if(filterDetails[i].operatorValue === "isAnyOf") {
              query = query +"IN (" + filterDetails[i].value.map(item => `'${item}'`).join(", ") +")";
            }
          }

          //This code is responsible for the Id Custome Filter. 
          if(filterDetails[i].columnField === "assetsId") {
            if(i === 0) {
              query = "where "
            } else {
              query = query + " and "
            }
            query = query +" id "
            if(filterDetails[i].operatorValue === "contains") {
              query = query +"LIKE '%"+filterDetails[i].value+"%'";
            }
            if(filterDetails[i].operatorValue === "equals") {
              query = query +"= '" + filterDetails[i].value+"'";
            }
            if(filterDetails[i].operatorValue === "startsWith") {
              query = query +"LIKE " + "'"+filterDetails[i].value+"%'";
            }
            if(filterDetails[i].operatorValue === "endsWith") {
              query = query +"LIKE " + "'%"+filterDetails[i].value+"'";
            }
            if(filterDetails[i].operatorValue === "isEmpty") {
              query = query +"is null";
            }
            if(filterDetails[i].operatorValue === "isNotEmpty") {
              query = query +"is not null";
            }
            if(filterDetails[i].operatorValue === "isAnyOf") {
              query = query +"IN (" + filterDetails[i].value.map(item => `'${item}'`).join(", ") +")";
            }
          }
    
          //This code is responsible for the programme Custome Filter. 
          if(filterDetails[i].columnField === "programme_1") {
            if(i === 0) {
              query = "where "
            } else {
              query = query + " and "
            }
            query = query +" programme "
            if(filterDetails[i].operatorValue === "contains") {
              query = query +"LIKE " + "'"+"%"+filterDetails[i].value+"%'";
            }
            if(filterDetails[i].operatorValue === "equals") {
              query = query +"= '" + filterDetails[i].value+"'";
            }
            if(filterDetails[i].operatorValue === "startsWith") {
              query = query +"LIKE " + "'"+filterDetails[i].value+"%'";
            }
            if(filterDetails[i].operatorValue === "endsWith") {
              query = query +"LIKE " + "'%"+filterDetails[i].value+"'";
            }
            if(filterDetails[i].operatorValue === "isEmpty") {
              query = query +"is null";
            }
            if(filterDetails[i].operatorValue === "isNotEmpty") {
              query = query +"is not null";
            }
            if(filterDetails[i].operatorValue === "isAnyOf") {
              query = query +"IN (" + filterDetails[i].value.map(item => `'${item}'`).join(", ") +")";
            }
          }
    
          //This code is responsible for the series Custome Filter. 
          if(filterDetails[i].columnField === "series_1") {
            if(i === 0) {
              query = "where "
            } else {
              query = query + " and "
            }
            query = query +" series "
            if(filterDetails[i].operatorValue === "contains") {
              query = query +"LIKE " + "'"+"%"+filterDetails[i].value+"%'";
            }
            if(filterDetails[i].operatorValue === "equals") {
              query = query +"= '" + filterDetails[i].value+"'";
            }
            if(filterDetails[i].operatorValue === "startsWith") {
              query = query +"LIKE " + "'"+filterDetails[i].value+"%'";
            }
            if(filterDetails[i].operatorValue === "endsWith") {
              query = query +"LIKE " + "'%"+filterDetails[i].value+"'";
            }
            if(filterDetails[i].operatorValue === "isEmpty") {
              query = query +"is null";
            }
            if(filterDetails[i].operatorValue === "isNotEmpty") {
              query = query +"is not null";
            }
            if(filterDetails[i].operatorValue === "isAnyOf") {
              query = query +"IN (" + filterDetails[i].value.map(item => `'${item}'`).join(", ") +")";
            }
          }
          //This code is responsible for the custom_id Custome Filter. 
          if(filterDetails[i].columnField === "custom_id") {
            if(i === 0) {
              query = "where "
            } else {
              query = query + " and "
            }
            query = query +" custom_id "
            if(filterDetails[i].operatorValue === "contains") {
              query = query +"LIKE " + "'"+"%"+filterDetails[i].value+"%'";
            }
            if(filterDetails[i].operatorValue === "equals") {
              query = query +"= '" + filterDetails[i].value+"'";
            }
            if(filterDetails[i].operatorValue === "startsWith") {
              query = query +"LIKE " + "'"+filterDetails[i].value+"%'";
            }
            if(filterDetails[i].operatorValue === "endsWith") {
              query = query +"LIKE " + "'%"+filterDetails[i].value+"'";
            }
            if(filterDetails[i].operatorValue === "isEmpty") {
              query = query +"is null";
            }
            if(filterDetails[i].operatorValue === "isNotEmpty") {
              query = query +"is not null";
            }
            if(filterDetails[i].operatorValue === "isAnyOf") {
              query = query +"IN (" + filterDetails[i].value.map(item => `'${item}'`).join(", ") +")";
            }
          }
        }
        try {
          if (page === "assets") {
              setLoading(true);
              let newData = [];
              let pageNumber = 0;
              let getresponse;
              do {
                  let encodedQuery = encodeURIComponent(query);
                  let endpoint = "";
                  if(pageNumber === 0) {
                    endpoint = `${page}?offset=${0}&limit=2500&filters=${encodedQuery}`
                    
                  } else if(pageNumber === 1){
                    endpoint = `${page}?offset=${2500}&limit=47500&filters=${encodedQuery}`;
                  } else {
                    endpoint = `${page}?offset=${(pageNumber-1) * 50000}&limit=50000&filters=${encodedQuery}`;
                  }
                  getresponse = await sendRequest({
                    requestType: "get",
                    endpoint: endpoint,
                  });
                  newData = [...newData, ...getresponse.data.results];
                  setData(newData);                  
                  setLoading(false);
                  if(getresponse.data.count != 0){
                    enqueueSnackbar(`Data is getting loaded in the background`, {variant: "success"})
                  }                  
                  console.log(newData);
                  pageNumber++;
                } while (getresponse.data.count > 0); // Continue fetching until response count becomes 0
              enqueueSnackbar(`Data Load completed`, {variant: "success"});
              console.log(newData);
              setData(newData);
            } 
          } catch (error) {
          console.error("Error fetching data:", error);
          enqueueSnackbar("Error fetching data", {variant: "error"});
        } finally {
          setLoading(false);
        }
      }
    }  
  };

  return (
    <>
      <Box sx={{height: "100%", width: "100%"}}>
        {page === 'assets' && assetsFilters.items.length > 0 ? (
          <Button disabled={assetsDataLoadFully} onClick={handleClick}>Apply Filter</Button>
          ):(
          <p></p>
        )}      
        <DataGridPremium
          columns={columns ? columns : []}
          rows={data ? data : []}
          pageSize={pageSize}
          rowsPerPageOptions={[100, 500, 2500]}
          onPageSizeChange={(size) => setPageSize(size)}
          disableSelectionOnClick
          checkboxSelection={page === "assets" || page === "unknown_assets"}
          columnVisibilityModel={{...columnVisibilityModel}}
          onColumnVisibilityModelChange={(model) =>
            setColumnVisibilityModel(model)
          }
          selectedColumns ={ columnVisibilityModel }
          // getRowId={(row) => {
          //   if (defaultParams && "row_id" in defaultParams)
          //     return defaultParams["row_id"];
          //   return row.id
          //     ? row.id
          //     : row.custom_id_stem
          //     ? row.custom_id_stem
          //     : row.custom_id;
          // }}
          getRowId={page === "unknown_assets" ? uniqId : defaultId}
          // getRowId={defaultId}
          initialState={{
            filter: {
              filterModel: {items: []},
            },
            // filter: {
            //   filterModel: filters,
            // },
            aggregation: {
              model: {
                gross_revenue_usd: "sum",
                gross_revenue_client_currency: "sum", 
              },
            },
          }}
          // initialState={{
          //   filter: {
          //     filterModel: {
          //       items: [],
          //       quickFilterExcludeHiddenColumns: true,
          //     },
          //   },
          // }}
          filterMode={assetsDataLoadFully === true ? "client" : "server"}
          experimentalFeatures={{aggregation: true}}
          // filterModel={page === "assets" ? filters : {items: []}}
          onFilterModelChange={handleFilterChange}
          loading={loading}
          pagination
          // paginationMode="server"
          page={pageNumber}
          //pageCount={5000}
          // rowCount={5000}
          onPageChange={(page) => setPageNumber(page)}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              bigQueryTable: true,
              handleQuickFilter,
              page,
              targetValue,
              open,
              setOpenUnknowAssetsUpload,
              setOpenAssetsExport,
              setOpenUnknownAssetsExport,
              selectedRows,
            },
            filterPanel: {
              linkOperators: [],
            },
          }}
          
          selectionModel={entries}
          onSelectionModelChange={(model) => {
            setEntries(model);
            selectedRowsForAssetAndUnknowAsset(model);
            setSelected(model);            
          }}
        />
        <UnknowAssetsUpload
          open={openUnknowAssetsUpload}   
          close={() => setOpenUnknowAssetsUpload(false)}       
        ></UnknowAssetsUpload>
        <AssetsExport
          open={openAssetsExport}
          close={() => setOpenAssetsExport(false)}
          selectedRows={selectedRows}
          selectedColumns={columnVisibilityModel}
        ></AssetsExport>
        <UnknownAssetsExport
          open={openUnknownAssetsExport}
          close={() => setOpenUnknownAssetsExport(false)}
          selectedRows={selectedRows}
          selectedColumns={columnVisibilityModel}
        ></UnknownAssetsExport>
        
      </Box>
    </>
  );
};

export default ManagePageBQ;

// https://localhost:3000/deals?custom_id_stem=SOT
