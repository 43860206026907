import {Button} from "@mui/material";
import {GridAddIcon} from "@mui/x-data-grid-pro";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SendIcon from "@mui/icons-material/Send";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
const is_access=localStorage.getItem("is_access")
console.log("is_access---",is_access)


const CustomToolbar = ({
  openEditDialogue,
  setOpenReportGen,
  bigQueryTable,
  page,
  selected,
  open,
  handleClickOpen,
  handleQuickFilter,
  targetValue,
  setOpenUnknowAssetsUpload,
  setOpenUnknownDealUpload,
  setOpenAssetsExport,
  setOpenUnknownAssetsExport,
  selectedRows,
  setOpenUnknownProgrammeUpload,
}) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      {!bigQueryTable && page !== "reports" && page !== "job" && is_access==='true' && (
        <Button
          startIcon={<GridAddIcon />}
          onClick={() => openEditDialogue("add")}
        >
          Add New
        </Button>
      )}
      <GridToolbarFilterButton
        nonce={""}
        onResize={() => null}
        onResizeCapture={() => null}
        componentsProps={{
          button: {
            //disabled: loading,
          },
        }}
      />
      {page === "reports" && (
        <Button
          onClick={() => handleClickOpen()}
          startIcon={<SendIcon />}
          disabled={selected.length < 1}
        >
          Email Report
        </Button>
      )}
      {page === "deals" && (
        <Button
          onClick={() => setOpenReportGen(true)}
          startIcon={<AssessmentIcon />}
          disabled={selected.length < 1}
        >
          Generate Reports
        </Button>
      )}
      {page === "assets" && (
        <Button
          onClick={() => setOpenUnknowAssetsUpload(true)}
          startIcon ={<CloudUploadIcon/>}
        >
          Upload CSV
        </Button>
      )}
      {page === "deals" && (
        <Button
          // This code is for Deal bulk upload!
          onClick={() => setOpenUnknownDealUpload(true)}
          startIcon ={<CloudUploadIcon/>}
        >
          Upload CSV
        </Button>
      )}
      {page === "programmes" && (
        <Button
          // This code is for Programme bulk upload!
          onClick={() => setOpenUnknownProgrammeUpload(true)}
          startIcon ={<CloudUploadIcon/>}
        >
          Upload CSV
        </Button>
      )}    
      {page === "assets" && (
        <Button
          onClick={() => setOpenAssetsExport(true)}
          startIcon ={<CloudDownloadIcon/>}
          disabled={selectedRows.length < 1}
        >
          Export
        </Button>
      )}
      {page === "unknown_assets" && (
        <Button
          onClick={() => setOpenUnknownAssetsExport(true)}
          startIcon ={<CloudDownloadIcon/>}
          disabled={selectedRows.length < 1}
        >
          Export
        </Button>
      )}
      {page === "assets" ? (
        <GridToolbarQuickFilter
          sx={{marginLeft: "auto"}}
          debounceMs={500}
          onChange={handleQuickFilter}
          value={targetValue}
        />
      ) : (
        <GridToolbarQuickFilter sx={{marginLeft: "auto"}} debounceMs={500} />
      )}
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
